/*
|--------------------------------------------------------------------------
| Section About us: Lalbaug Community
|--------------------------------------------------------------------------
*/

.about-us{
  background-color: #e9f0f6;

  .banner-content-center{
    bottom: 150px !important;
  }

  .section-about-us{
    .section-about-us-header{
      font-size: $font-xxl;
      font-weight: 800;
      text-align: center;
      padding: 20px;
    }

    .section-about-us-subheader{
      font-size: $font-s;
      text-align: center;
      line-height: 1.2;
      padding: 0px 20px 20px 20px;
    }

    .about-content-con{
      padding: 30px 15px 30px 15px;

      .about-img-col{
        box-shadow: 1px -1px 8px 0px #d6d9e0;
        padding: 0px !important;

        .about-img-content{
          height: 100%; 
          width: 100%;
        }
      }
      
      .about-con-col{
        padding: 50px 70px 50px 70px;
        background-color: white;
        box-shadow: 1px -1px 8px 0px #d6d9e0;

        .about-content{
          text-align: justify;
          font-size: $font-xs;
        }
      }
    }
  }
}

@media (max-width : 1024px) {
  .row-even{
    display: flex;
    flex-wrap: wrap-reverse;
  }
}
/*
|--------------------------------------------------------------------------
| Section About us: Our Donors
|--------------------------------------------------------------------------
*/

.donor{
  background-color: #e9f0f6;

  .banner-content-center{
    bottom: 150px !important;
  }

  .section-donor{
    .section-donor-header{
      font-size: $font-xxl;
      font-weight: 800;
      text-align: center;
      padding: 20px;
    }

    .section-donor-subheader{
      font-size: $font-s;
      text-align: center;
      line-height: 1.2;
      padding: 0px 20px 20px 20px;
    }

    .card-donor {
      padding-top: 40px;
      // padding-bottom:70px;

      .card-donor-con{
        .donor-col{
          .donor-card-container{
            box-shadow: 2px 4px 20px 5px #bdb9b9;
            height: 100%;

            .donor-card-header {
              padding: 30px 30px;
              width: 100%;
              height: 90px;
              background-position: center;
              background-repeat: no-repeat;
              background-origin: content-box;
              // background-image: url('/images/About Us/Our Donors/1.png');
              background-size: 150px 90px;
            }

            .donor-card-footer{
              padding: 30px 30px;
              font-size: $font-xs;
              text-align: justify;
              color: white;
              // height: 300px;
            }
          }
        }
      }
    }
  }
}

/*
|--------------------------------------------------------------------------
| Section SchoolModel
|--------------------------------------------------------------------------
*/

.school-model{
  background-color: #e9f0f6;

  .section-banner{
    .banner-carousel{
      .banner-img{
        background-position-y: top;

        .banner-content-center{
          bottom: 150px !important;
        }
      }
    }
  }

  .section-school-model{
    .school-model-header{
      font-size: $font-xxl;
      font-weight: 800;
      text-align: center;
      padding: 20px;
    }

    .school-model-subheader{
      font-size: $font-s;
      text-align: center;
      line-height: 1.2;
      padding: 20px;
    }

    .pedagogy-card-con{
      box-shadow: 2px 4px 20px 5px #bdb9b9;
      padding: 30px 40px 30px 40px;
      color: white;

      .pedagogy-card-header{
        font-size: $font-xl;
        padding: 20px;
        text-align: center;
      }

      .pedagogy-card-content{
        font-size: $font-s;
        text-align: justify;
      }
    }

    .panel-group .panel {
      border-radius: 0;
      box-shadow: none;
      border-color: #EEEEEE;
      background-color: white;
    }

    .panel-default > .panel-heading {
      padding: 0;
      border-radius: 0;
      color: #212121;
      background-color: $color-blue;
      border-color: #EEEEEE;
    }

    .panel-title {
      font-size: 18px;
    }

    .panel-title > a {
      display: block;
      padding: 15px;
      text-decoration: none;
    }

    .more-less {
      float: right;
      color: white;
    }

    .panel-body{
      padding: 20px;
      font-size: $font-xs;
    }

    .panel-default > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #EEEEEE;
    }

    .community-content-con{
      padding: 30px 15px 30px 15px;

      .community-img-col{
        box-shadow: 1px -1px 8px 0px #d6d9e0;
        padding: 0px !important;

        .community-img-content{
          height: 100%; 
          width: 100%;
        }
      }
      
      .community-con-col{
        padding: 50px 70px 50px 70px;
        background-color: white;
        box-shadow: 1px -1px 8px 0px #d6d9e0;

        .community-content{
          text-align: justify;
          font-size: $font-xs;
        }
      }
    }

    .holistic-card-con{
      .holistic-card-col{
        margin-bottom: 16px;
        padding: 0 8px;

        .holistic-card{
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          height: 100%;

          .holistic-card-content{
            padding: 15px;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            /* justify-content: flex-end; */
            align-items: flex-end;

            .holistic-card-button{
              border: none;
              outline: 0;
              display: inline-block;
              padding: 8px;
              color: white;
              // background-color: $color-blue;
              text-align: center;
              cursor: pointer;
              width: 100%;
              text-decoration: none;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.holistic-card-content::after, .holistic-card-con::after {
  content: "";
  clear: both;
  display: table;
}

@media (max-width : 1024px) {
  .row-even{
    display: flex;
    flex-wrap: wrap-reverse;
  }
}

@media screen and (max-width: 650px) {
  .holistic-card-col {
    width: 100%;
    display: block;
  }
}
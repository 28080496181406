// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$primary-color: #caebf2;
$secondary-color: #a9a9a9;
$ternary-color: #ff3b3f;
$quaternary-color: #efefef;
$color-blue: #2A5CB7;
$color-green: #17A05F;


//Font-sizes
$font-xs: 16px;
$font-s: 18px;
$font-m: 21px;
$font-l: 24px;
$font-xl: 30px;
$font-xxl: 36px;

//Background Gradient

.bg-gr-tr{
	background-image: linear-gradient(to right bottom, #ff3b3f, #e02e30, #c22121, #a51313, #890205);
}

.bg-gr-blue{
    // background-image: linear-gradient(to right top, #154294, #2654ae, #3567c8, #437ae3, #508eff);
    background-image: linear-gradient(to right top, #2a84ff, #2c7aed, #2c70da, #2b66c9, #2a5cb7);
}

.bg-gr-green{
    background-image: linear-gradient(to right top, #17a05f, #14b066, #11c06d, #0ed074, #0be07b);
}

.color-bg-primary {
    background-color: #ef6434;
}

.color-bg-secondary {
    background-color: #ef8c29;
}

.color-bg-tertiary {
    background-color: #edb52b;
}

.color-bg-green {
    background-color: #17A05F;
}

.color-bg-blue {
    background-color:  #003698;
}

.color-bg-red {
    background-color:  #E01010;
}

.color-bg-white {
    background-color:  #fff;
}

//Button
.btn-educo-primary {
    width: 180px;
    background-color: #e02e30 !important;
    font-size: 21px !important;
    height: 45px;
    color: #fff !important;
    font-weight: bold !important;
}

.btn-educo-download {
    width: 100px;
    background-color: #2a5cb7 !important;
    font-size: 15px !important;
    height: 35px;
    color: #fff !important;
    font-weight: bold !important;
}

.btn-educo-form {
    width: 100px;
    font-size: 15px !important;
    height: 35px;
    color: #fff !important;
    font-weight: bold !important;
    background-color: #2a5cb7 !important;
    // background-color: #82b1ff !important;
}

//Padding
.pad-3 {
    padding: 0px !important;
}

.pad-top-bottom{
    padding-top: 40px;
    padding-bottom: 40px;
}

.pad-pedagogy-card{
    padding: 20px;
}

//Margin
.mar-lr-3 {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

/*
|--------------------------------------------------------------------------
| Section About us: Financial Information
|--------------------------------------------------------------------------
*/

.financial{
  background-color: #e9f0f6;

  .banner-content-center{
    bottom: 150px !important;
  }

  .section-financial{
    .section-financial-header{
      font-size: $font-xxl;
      font-weight: 800;
      text-align: center;
      padding: 20px;
    }

    .section-financial-subheader{
      font-size: $font-s;
      text-align: center;
      line-height: 1.2;
      padding: 0px 20px 20px 20px;
    }

    .financial-content-con{
      padding: 30px 15px 30px 15px;

      .financial-img-col{
        box-shadow: 1px -1px 8px 0px #d6d9e0;
        padding: 0px !important;

        .financial-img-content{
          height: 100%; 
          width: 100%;
        }
      }
      
      .financial-con-col{
        padding: 50px 70px 50px 70px;
        background-color: white;
        box-shadow: 1px -1px 8px 0px #d6d9e0;

        .financial-content{
          text-align: justify;
          font-size: $font-xs;
        }
      }
    }

    .financial-record {
      font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    .financial-record td, .financial-record th {
      border: 1px solid #ddd;
      padding: 8px;
    }

    .financial-record tr:nth-child(even){background-color: #f2f2f2;}

    .financial-record tr:hover {background-color: #ddd;}

    .financial-record th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: $color-blue;
      color: white;
    }
  }
}

@media (max-width : 1024px) {
  .row-even{
    display: flex;
    flex-wrap: wrap-reverse;
  }
}
/*
|--------------------------------------------------------------------------
| Section About us: Our Team
|--------------------------------------------------------------------------
*/

.team{
  background-color: #e9f0f6;

  .banner-content-center{
    bottom: 150px !important;
  }

  .section-team{
    .section-team-header{
      font-size: $font-xxl;
      font-weight: 800;
      text-align: center;
      padding: 20px;
    }

    .section-team-subheader{
      font-size: $font-s;
      text-align: center;
      line-height: 1.2;
      padding: 0px 20px 20px 20px;
    }

    .about-content-con{
      padding: 30px 15px 30px 15px;

      .about-img-col{
        box-shadow: 1px -1px 8px 0px #d6d9e0;
        padding: 0px !important;

        .about-img-content{
          height: 100%; 
          width: 100%;
        }
      }
      
      .about-con-col{
        padding: 50px 70px 50px 70px;
        background-color: white;
        box-shadow: 1px -1px 8px 0px #d6d9e0;

        .about-content{
          text-align: justify;
          font-size: $font-xs;
        }
      }
    }

    .teachers-record {
      font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    .teachers-record td, .teachers-record th {
      border: 1px solid #ddd;
      padding: 8px;
    }

    .teachers-record tr:nth-child(even){background-color: #f2f2f2;}

    .teachers-record tr:hover {background-color: #ddd;}

    .teachers-record th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: #17A05F;
      color: white;
    }

    .maincard {
      position: relative;
      // width: 300px;
      height: 300px;

      .thecard {
        position: absolute;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transition: all 0.8s ease;

        .thefront {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: hidden;
          color: #fff;
          border-radius: 10px;
          cursor: pointer;
          border: 1px solid #eee;
          box-shadow: 5px 6px 6px 2px #e9ecef;
          padding: 10px;

          .user_name {
            color: #eee;
            font-size: 12px;
            margin-top: 0px;
          }
        }

        .theback {
          position: absolute;
          width: 100%;
          height: 100%;
          overflow: hidden;
          cursor: pointer;
          background-color: hidden;
          color: #fff;
          border-radius: 10px;
          transform: rotateY(180deg);
          border: 1px solid #eee;
          box-shadow: 5px 6px 6px 2px #e9ecef;
          padding: 10px;
          opacity: 0;
          transition-property: opacity;
          transition-duration: 0.2s;
          transition-delay: 0.2s;
          overflow-y: scroll;
          scrollbar-width:2px;
          scrollbar-width: thin;

          .about {
            font-size: 12px;
          }
        }
      }

      .thecard:hover {
        transform: rotateY(180deg);
        .theback{
          opacity: 1;
        }
      }
    } 
  }
}

/*
|-----------------------------------------------------------
| Header CSS 
|-----------------------------------------------------------
*/

.navbar {
    transition: all 0.4s;
    background: rgba(1, 1, 1, .35);
    height: 60px;

    .navbar-brand{
      padding:0px;
        .header-logo{
            height: 47px;
        }
    }

    .nav-link {
        color: white;
        font-size: $font-xs;
    }

    .dropdown-toggle::after {
        content: none;
    }
}

.navbar .nav-link:hover,
.navbar .nav-link:focus {
    color: $color-blue;
}

/* Change navbar styling on scroll */
.navbar.active {
    background: #fff;
    box-shadow: 0px 1px 5px 0px #888888;
}

.navbar.active .nav-link {
    color: black;
}

.navbar.active .nav-link:hover,
.navbar.active .nav-link:focus {
    color: $color-blue;
}

/*
|-----------------------------------------------------------
| Animation CSS: Slick Carousel Overlay text (not used)
|-----------------------------------------------------------
*/

.rotate {
    transition-property: transform;
    transition-duration: 1s;
}

.rotate:hover {
    animation-name: rotate; 
    animation-duration: 2s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.ani-fadeInUp, .ani-fadeInRight {
    opacity: 0;
}

@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

/*
|--------------------------------------------------------------------------
| Section Sticky Social Icon 
|--------------------------------------------------------------------------
*/

.icon-bar {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 9999;
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 3px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #3B5998;
  color: white;
}

.twitter {
  background: #55ACEE;
  color: white;
}

.google {
  background: #dd4b39;
  color: white;
}

.linkedin {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

/*
|-----------------------------------------------------------
| Footer
|-----------------------------------------------------------
*/

.footer-dark {
  padding:50px 0 0 0;
  color:#f0f9ff;
  background-color:#282d32;
}

.footer-dark h3 {
  margin-top:0;
  margin-bottom:12px;
  font-weight:bold;
  font-size:16px;
}

.footer-dark ul {
  padding:0;
  list-style:none;
  line-height:1.6;
  font-size:14px;
  margin-bottom:0;
}

.footer-dark ul a {
  color:inherit;
  text-decoration:none;
  opacity:0.6;
}

.footer-dark ul a:hover {
  opacity:0.8;
}

@media (max-width:767px) {
  .footer-dark .item:not(.social) {
    text-align:center;
    padding-bottom:20px;
  }
}

.footer-dark .item.text {
  margin-bottom:36px;
}

@media (max-width:767px) {
  .footer-dark .item.text {
    margin-bottom:0;
  }

  .footer-dark .copyright {
    text-align: center !important;
   }
}

.footer-dark .item.text p {
  opacity:0.6;
  margin-bottom:0;
}

.footer-dark .item.social {
  // text-align:center;
  margin-top: 10px;
}

@media (max-width:991px) {
  .footer-dark .item.social {
    text-align:center;
    margin-top:20px;
  }

  .footer-dark .copyright {
    text-align: center !important;
   }
}

.footer-dark .item.social > a {
  font-size:20px;
  width:30px;
  height:30px;
  line-height:30px;
  display:inline-block;
  text-align:center;
  border-radius:50%;
  box-shadow:0 0 0 1px rgba(255,255,255,0.4);
  margin:0 8px;
  color:#fff;
  // opacity:0.75;
}

.footer-dark .item.social > a:hover {
  opacity:0.9;
}

.footer-dark .copyright {
  text-align: right;
  padding-top:24px;
  opacity:0.3;
  font-size:15px;
  margin-bottom:0;
}

.facebook {
  background: #3B5998;
  color: white;
}

.twitter {
  background: #55ACEE;
  color: white;
}

.google {
  background: #dd4b39;
  color: white;
}

.linkedin {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

.phone {
  font-size: 22px !important;
  color: white;
}

.footer-pencil{
  background-image: url('/images/Educo Image Folder/footer_pencil.png');
  background-repeat: repeat;
  height: 120px;
}

.my-wrapper{
  margin-top: 60px;
}
/*
|--------------------------------------------------------------------------
| Section Banner Carousel
|--------------------------------------------------------------------------
*/

.slick-dots {
  bottom: 8px !important;
}

.slick-dotted.slick-slider{
  margin-bottom: 0px !important;
} 

.slick-list{
  margin-bottom: -7px !important;
}

.banner-carousel{

  .banner-img{
    background-color: $primary-color;
    background-size: cover;
    background-repeat:  no-repeat;
    background-position: center;
    height:calc(100vh - 60px);
    position: relative;
    
    .banner-content-con{
      position: absolute;
      left: 50%;
      bottom: 20px;
      width: 410px;
      margin-left: 10px;
      margin-right: 10px;
      transform: translateX(-225px);

      .banner-content-col{
        background: rgba(1, 1, 1, .35);
        opacity: 1;
        border-radius: 5px;
        padding: 20px;
      }

      .banner-header{
        text-align: center;
        font-size: $font-l;
        color: #e6e6e6;
      }

      .banner-subheader{
        text-align: center;
        font-size: $font-s;
        line-height: 21px;
        color: #e6e6e6;
      }
    }
  }

  .slick-next{
    right: 18px !important;
    z-index: 1;
  }

  .slick-prev {
    left: 13px !important;
    z-index: 1;
  }
}

@media (max-width : 479px) {
  .banner-carousel{
    .banner-img{
      height: 300px;
    }
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .banner-carousel{
    .banner-img{
      height: 350px;
    }
  }
}

@media (min-width : 768px) and (max-width : 1023px) {
  .banner-carousel{
    .banner-img{
      height: 400px;
    }
  }
}

@media (min-width : 1024px) {
  .banner-carousel{
    .banner-img{
      height: calc(100vh - 60px);;
    }
  }
}

/*
|--------------------------------------------------------------------------
| Section Welcome
|--------------------------------------------------------------------------
*/

.section-welcome{
  position: relative;
  display: flex;

  .welcome-left{
    background-color: white;
    width: 20%;
  }

  .welcome-right{
    width: 80%;

    .welcome-icon{
      position: absolute;
      /*top: 50%;*/
      transform: translate(-50%, -50%);
      border: 6px solid $color-blue;
      box-shadow: 1px -1px 8px 0px #2A5CB7;
    }

    .welcome-content-con{
      color: white;
      padding: 70px 70px 70px 250px;

      .welcome-header{
        font-size: $font-xl;
        text-align: center;
        // color: $primary-color;
      }

      .welcome-content{
        font-size: $font-s;
        text-align: justify;
      }
    }
  }
}

@media (max-width : 1024px) {
  .section-welcome{
    display: block;

    .welcome-left{
      width: 100%;
      height: 180px;
    }

    .welcome-right{
      width: 100%;

      .welcome-icon{
        left: 50%;
      }

      .welcome-content-con{
        padding: 150px 20px 20px 20px;
      }
    }
  }
}

@media (min-width : 1025px) {
  .section-welcome{
    .welcome-right{
      .welcome-icon{
        top: 50%;
      }
    }
  }
}

/*
|--------------------------------------------------------------------------
| Section About
|--------------------------------------------------------------------------
*/

.section-about{
  // background-image: url('/images/educo/home_page_vision/Reference_Pic_10.jpg');
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/images/educo/home_page_vision/Reference_Pic_10.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #1a303b;

  .section-about-container{
    padding-top: 70px;
    padding-bottom: 50px;

    .section-about-content{
      color: white;

      .about-left-con{
        .about-left-header{
          font-size: 40px;
          font-weight: bold;
          border-left: 10px solid #e02e30;
          padding: 10px;
        }

        .about-left-content{
          padding-top: 15px;
          font-size: $font-s;
        }

        .about-btn-container{
          margin-top: 50px;
          margin-bottom: 50px;
        }
      }

      .about-right-con{
        .about-right-header{
          font-size: $font-l;
          font-weight: bold;
          padding: 10px;
          color: #e02e30 !important;
        }

        .about-right-content{
          padding: 10px;

          .about-right-icon{
            filter: invert(1);
            float: left;
            height: 70px;
            padding-right: 15px;
          }

          .about-right-content-header{
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

/*
|--------------------------------------------------------------------------
| Section Intro: Who-we-are
|--------------------------------------------------------------------------
*/

.section-intro{
  background-image: url('/images/Educo Image Folder/Homepage/child.jpg');
  background-color: white;
  background-repeat:  no-repeat;
  background-position: right bottom;
  position: relative;

  .intro-content-con{
    padding: 70px;

    .intro-header{
      font-size: $font-xl;
      text-align: center;
    }

    .intro-content{
      font-size: $font-s;
      text-align: justify;
    }

    .intro-subcontent{
      font-size: $font-s;
    }
  }
}

@media (max-width : 1024px) {
  .intro-content-con{
    padding: 20px !important;
  }
}

/*
|--------------------------------------------------------------------------
| Section Card: Problem/Solution
|--------------------------------------------------------------------------
*/

.section-why {
  padding: 70px;
  background-color: white;
}

.section-why .why-block-container {
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}

.section-why .why-block {
  margin-left: 100px;
  padding: 20px 30px 20px 90px;
  color: #fff;
  border-radius: 12px;
  height: 100%;
}

.section-why .why-block .why-header {
  padding-bottom: 15px;
  font-weight: 600;
  font-size: $font-s;
}

.section-why .why-block .why-footer {
  font-size: $font-xs;
  text-align: justify;
}

.why-block-container .why-shadow {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 115px;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: -1px 0px 9px 0px #795f56;
}

.why-block-container .why-icon {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70px 70px;
}

.why-block-container .why-icon1 {
  background-image: url('/images/Educo Image Folder/Homepage/child.jpg');
}

.why-block-container .why-icon2 {
  background-image: url('/images/Educo Image Folder/Homepage/child.jpg');
}

@media only screen and (max-width: 767px) {
  .section-why .why-block .why-header {
    text-align: center;
  }
  
  .why-block-container .why-shadow {
    top: 40px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .section-why .why-block {
    margin-left: 0px;
    padding: 70px 20px 20px 20px;
    color: #fff;
    border-radius: 12px;
    height: 100%;
  }

  .section-why .why-block-container {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
  }
}

@media (max-width : 1024px) {
  .section-why {
    padding: 20px 30px 20px 20px;
  }
}

/*
|--------------------------------------------------------------------------
| Section Approach
|--------------------------------------------------------------------------
*/
.section-approach{
  background-image: url('/images/Educo Image Folder/Homepage/Section Approach/doodle.png');
  background-repeat: repeat;

  .approach-content-con{
    padding: 70px;

    .approach-header{
      text-align: center;
      font-size: $font-xl;
      color: white;
    }

    .approach-content{
      text-align: center;
      font-size: $font-s;
      color: white;
      padding-bottom: 30px;
    }

    .approach-carousel{
      .approach-card-main-container{
        padding: 70px 15px 0px 15px;

        .approach-card-container{
          background-color: white;
          border-radius: 150px;
          padding: 20px;
          position: relative;

          .approach-card-header{
            position: absolute;
            left: 50%;
            transform: translate(-50%, -70%);

            .approach-card-header-icon{
              width: 120px;
              height: 120px;
              border-radius: 50%;
              background-color: #fff;
              box-shadow: 8px 5px 20px -4px #795f56;
            }
          }

          .approach-card-content{
            font-size: $font-s;
            text-align: center;
            padding: 50px 10px 15px 10px;
          }

          .approach-card-footer{
            font-size: $font-xs;
            text-align: center;
            color: #ad1717;
          }
        }
      }

      .slick-prev {
        left: 50%;
        z-index: 1;
        top: 100%;
        left: 50%;
        transform: translate(-25px,10px);
      }

      .slick-next{
        right: 50%;
        z-index: 1;
        top: 100%;
        transform: translate(25px, 10px);
      }
    }
  }
}

@media (max-width : 1024px) {
  .section-approach{
    .approach-content-con{
      padding: 70px 20px 60px 20px;

      .approach-carousel{
        .approach-card-main-container{
          .approach-card-container{
            border-radius: 75px;
          }
        }

      }   
    }
  }
}

    
/*
|--------------------------------------------------------------------------
| Section Model:What-we-do
|--------------------------------------------------------------------------
*/

.section-model{
  background-image: url('/images/Educo Image Folder/Homepage/mask.jpg');
  background-color: white;
  background-repeat:  no-repeat;
  background-position: top left;
  position: relative;

  .model-content-con{
    padding: 70px;

    .model-header{
      font-size: $font-xl;
      text-align: center;
      margin-bottom: 30px;
    }

    .model-content{
      font-size: $font-s;
      text-align: justify;
    }
  }
}

@media (max-width : 1024px) {
  .model-content-con{
    padding: 20px !important;
  }
}

/*
|--------------------------------------------------------------------------
| Section MCGM
|--------------------------------------------------------------------------
*/

.section-mcgm{
  display: flex;
  position: relative;

  .mcgm-right{
    background-color: white;
    width: 20%;
  }

  .mcgm-left{
    width: 80%;

    .mcgm-icon{
      width: fit-content;
      position: absolute;
      border: 6px solid $color-blue;
      box-shadow: 1px -1px 8px 0px #2A5CB7;
    }

    .mcgm-content-con{
      color: white;
      padding: 70px 250px 70px 70px;

      .mcgm-header{
        font-size: $font-xl;
        text-align: center;
        // color: $primary-color;
      }

      .mcgm-content{
        font-size: $font-s;
        text-align: justify;
      }

      .mcgm-subcontent{
        font-size: $font-s;
        text-align: justify;

        .subcontent-list{
          font-size: $font-xs !important;
          line-height: 2;
          text-align: justify;
        }
      }
    }
  }
}

@media (max-width : 1279px) {
  .section-mcgm{
    display: flex;
    flex-wrap: wrap-reverse;

    .mcgm-right{
      width: 100%;
      height: 180px;
    }

    .mcgm-left{
      width: 100%;

      .mcgm-icon{
        transform: translate(-175px, -125px);
        left: 50%;
      }

      .mcgm-content-con{
        padding: 140px 20px 20px 20px;
      }
    }
  }
}

@media (min-width : 1280px) {
  .section-mcgm{
    .mcgm-left{
      .mcgm-icon{
        transform: translate(-100px, -125px);
        right: 0;
        top: 50%;
      }
    }
  }
}

/*
|--------------------------------------------------------------------------
| Section Initiatives
|--------------------------------------------------------------------------
*/

.section-initiatives{
  background-color: white;

  .section-initiatives-container{
    padding: 30px;

    .section-initiatives-header{
      text-align: center;
      font-size: $font-xl;
      padding: 30px;
    }

    #grid-row img{
      border:1px solid #000;
    }

    .img-con{
      position: relative;

      .grid-images{
        width: 100%;
      }

      .ini-header{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: $font-xs;
        color: white;
        background: rgba(1, 1, 1, .40);
        opacity: 1;
        padding: 5px;
      }
    }


  }
}

/*
|--------------------------------------------------------------------------
| Section Videos
|--------------------------------------------------------------------------
*/

.section-video{
  background-color: white;

  .section-video-container{
    padding: 30px;

    .video-con{
      position: relative;

      .section-video-content{
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
      }

      // .section-video-header{
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   width: 100%;
      //   text-align: center;
      //   font-size: $font-xs;
      //   color: white;
      //   background: rgba(1, 1, 1, .40);
      //   opacity: 1;
      //   padding: 5px;
      // }
    }
  }
}

/*
|--------------------------------------------------------------------------
| Section Donors
|--------------------------------------------------------------------------
*/

.section-donors{
  .donors-content-con{
    padding: 30px;

    .donors-header{
      text-align: center;
      font-size: $font-xl;
      color: white;
    }

    .donors-carousel{
      padding: 30px 15px 30px 15px;

      .donors-card-container{
        width: fit-content !important;
        background-color: white;
        box-shadow: 1px -1px 2px 0px #e84242;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 10px;

        .donors-card-img{
          width: 220px;
          height: 120px;
        }
      }

      .slick-dots{
        margin: -20px;
      }
      .slick-active{
        text-align: center;
      }
    }
  }
}




/*
|--------------------------------------------------------------------------
| Section Contact
|--------------------------------------------------------------------------
*/

.contact{
  background-color: #e9f0f6;

  .section-banner{
    .banner-carousel{
      .banner-img{
        background-position-y: top;

        .banner-content-center{
          bottom: 150px !important;
        }
      }
    }
  }

  .contact-card-con{
    .card{
      font-weight: 400;
      border: 0;
      box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);

      .card-body{
        .form-header {
          padding: 1rem;
          margin-top: -3.13rem;
          margin-bottom: 3rem;
          color: #fff;
          text-align: center;
          border-radius: .125rem;
          box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
          background-color: #2a5cb7 !important;
          // background-color: #82b1ff !important;
        }

        .md-form {
          position: relative;
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;

          .prefix {
            position: absolute;
            top: .25rem;
            font-size: 1.75rem;
            transition: color 0.2s;
            color: #9e9e9e !important;
          }

          .form-control{
            height: auto;
            padding: .6rem 0 .4rem 0;
            margin: 0 0 .5rem 0;
          }

          label{
            font-weight: 300;
            display: inline-block;
            margin-bottom: .5rem;
            position: absolute;
            top: 0;
            left: 0;
            font-size: 1rem;
            color: #757575;
            cursor: text;
            transform: translateY(12px);
            transform-origin: 0% 100%;
            transition: transform .2s ease-out, color .2s ease-out, -webkit-transform .2s ease-out;
          }

          .waves-effect {
            position: relative;
            overflow: hidden;
          }
        }
      }
    }

    .map-container-section {
      overflow:hidden;
      padding-bottom:52.25%;
      position:relative;
      height:460px;
    }
    .map-container-section iframe {
      left:0;
      top:0;
      height:100%;
      width:100%;
      position:absolute;
    }

    .address-footer {
      .btn-floating {
        position: relative;
        z-index: 1;
        display: inline-block;
        padding: 0;
        margin: 10px;
        overflow: hidden;
        vertical-align: middle;
        cursor: pointer;
        border-radius: 50%;
        box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
        transition: all 0.2s ease-in-out;
        width: 47px;
        height: 47px;
        background-color: #2a5cb7 !important;
        // background-color: #82b1ff !important;

        i {
          display: inline-block;
          width: inherit;
          color: #fff;
          text-align: center;
          font-size: $font-l;
          line-height: 47px;
        }
      }

      p {
        font-size: 1rem;
        line-height: 1.5;
        color: #212529;
      }
    }
  }
}

.md-form input:not([type]), .md-form input[type="text"]:not(.browser-default), .md-form input[type="password"]:not(.browser-default), .md-form input[type="email"]:not(.browser-default), .md-form input[type="url"]:not(.browser-default), .md-form input[type="time"]:not(.browser-default), .md-form input[type="date"]:not(.browser-default), .md-form input[type="datetime"]:not(.browser-default), .md-form input[type="datetime-local"]:not(.browser-default), .md-form input[type="tel"]:not(.browser-default), .md-form input[type="number"]:not(.browser-default), .md-form input[type="search"]:not(.browser-default), .md-form input[type="phone"]:not(.browser-default), .md-form input[type="search-md"], .md-form textarea.md-textarea {
  box-sizing: content-box;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

.md-form .prefix ~ input, .md-form .prefix ~ textarea {
  width: calc(100% - 2.5rem);
  margin-left: 2.5rem;
}

.md-form .prefix ~ label {
  margin-left: 2.5rem;
}

.md-form>label {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.md-form input:not([type])+label::after, .md-form input[type="text"]:not(.browser-default)+label::after, .md-form input[type="password"]:not(.browser-default)+label::after, .md-form input[type="email"]:not(.browser-default)+label::after, .md-form input[type="url"]:not(.browser-default)+label::after, .md-form input[type="time"]:not(.browser-default)+label::after, .md-form input[type="date"]:not(.browser-default)+label::after, .md-form input[type="datetime"]:not(.browser-default)+label::after, .md-form input[type="datetime-local"]:not(.browser-default)+label::after, .md-form input[type="tel"]:not(.browser-default)+label::after, .md-form input[type="number"]:not(.browser-default)+label::after, .md-form input[type="search"]:not(.browser-default)+label::after, .md-form input[type="phone"]:not(.browser-default)+label::after, .md-form input[type="search-md"]+label::after, .md-form textarea.md-textarea+label::after {
  position: absolute;
  top: 65px;
  display: block;
  content: "";
  opacity: 0;
  transition: 0.2s opacity ease-out,0.2s color ease-out;
}

a.waves-effect, a.waves-light {
  display: inline-block;
}
/*
|--------------------------------------------------------------------------
| Section About us: Our Partners
|--------------------------------------------------------------------------
*/

.partner{
  background-color: #e9f0f6;

  .banner-content-center{
    bottom: 150px !important;
  }

  .section-partner{
    .section-partner-header{
      font-size: $font-xxl;
      font-weight: 800;
      text-align: center;
      padding: 20px;
    }

    .section-partner-subheader{
      font-size: $font-s;
      text-align: center;
      line-height: 1.2;
      padding: 0px 20px 20px 20px;
    }

    .card-partner{
      position: relative;
      display: flex;
      padding-top: 30px;
      padding-bottom: 30px;

      .partner-left-odd{
        // background-color: white;
        width: 20%;
      }

      .partner-right-odd{
        width: 80%;
        border-radius: 10px;
        box-shadow: 1px 2px 8px 0px #a0a0a0;

        .partner-icon-odd{
          position: absolute;
          transform: translate(-50%, -50%);
          border: 6px solid $color-blue;
          box-shadow: 1px -1px 8px 0px #2A5CB7;
          border-radius: 10px;
        }

        .partner-content-con-odd{
          color: white;
          padding: 70px 70px 70px 200px;

          .partner-header{
            font-size: $font-xl;
            text-align: center;
          }

          .partner-content{
            font-size: $font-s;
            text-align: justify;
          }
        }
      }

      .partner-left-even{
        width: 80%;
        border-radius: 10px;
        box-shadow: 1px 2px 8px 0px #a0a0a0;

        .partner-icon-even{
          width: fit-content;
          position: absolute;
          border: 6px solid $color-green;
          box-shadow: 1px -1px 8px 0px #28af6f;
          border-radius: 10px;
        }

        .partner-content-con-even{
          color: white;
          padding: 70px 200px 70px 70px;

          .partner-header{
            font-size: $font-xl;
            text-align: center;
          }

          .partner-content{
            font-size: $font-s;
            text-align: justify;
          }
        }
      }

      .partner-right-even{
        // background-color: white;
        width: 20%;
      }
    }
  }
}

@media (max-width : 1024px) {
  .partner{
    .section-partner{
      .card-partner-odd{
        display: block;

        .partner-left-odd{
          width: 100%;
          height: 180px;
        }

        .partner-right-odd{
          width: 100%;

          .partner-icon-odd{
            left: 50%;
          }

          .partner-content-con-odd{
            padding: 150px 20px 20px 20px;
          }
        }
      }
    }
  }
}

@media (min-width : 1025px) {
  .partner{
    .section-partner{
      .card-partner-odd{
        .partner-right-odd{
          .partner-icon-odd{
            top: 50%;
          }
        }
      }
    }
  }
}

@media (max-width : 1279px) {
  .partner{
    .section-partner{
      .card-partner-even{
        display: flex;
        flex-wrap: wrap-reverse;

        .partner-right-even{
          width: 100%;
          height: 180px;
        }

        .partner-left-even{
          width: 100%;

          .partner-icon-even{
            transform: translate(-50%, -50%);
            left: 50%;
          }

          .partner-content-con-even{
            padding: 140px 20px 20px 20px;
          }
        }
      }
    }
  }
}

@media (min-width : 1280px) {
  .partner{
    .section-partner{
      .card-partner-even{
        .partner-left-even{
          .partner-icon-even{
            transform: translate(-50%, -50%);
            right: 0;
            top: 50%;
          }
        }
      }
    }
  }
}
/*
|--------------------------------------------------------------------------
| Section Get Involved
|--------------------------------------------------------------------------
*/

.get-involved{
  background-color: #e9f0f6;

  .section-banner{
    .banner-carousel{
      .banner-img{
        background-position-y: top;

        .banner-content-center{
          bottom: 150px !important;
        }
      }
    }
  }

  .section-get-involved{
    .get-involved-header{
      font-size: $font-xxl;
      font-weight: 800;
      text-align: center;
      padding: 20px;
    }

    .get-involved-subheader{
      font-size: $font-s;
      text-align: center;
      line-height: 1.2;
      padding: 20px;
    }

    .maincard {
      position: relative;
      // width: 300px;
      height: 300px;

      .thecard {
        position: absolute;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transition: all 0.8s ease;

        .thefront {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: hidden;
          color: #fff;
          border-radius: 10px;
          cursor: pointer;
          border: 1px solid #eee;
          box-shadow: 5px 6px 6px 2px #e9ecef;
          padding: 10px;

          .user_name {
            color: #eee;
            font-size: 12px;
            margin-top: 0px;
          }
        }

        .theback {
          position: absolute;
          width: 100%;
          height: 100%;
          overflow: hidden;
          cursor: pointer;
          background-color: hidden;
          color: #fff;
          border-radius: 10px;
          transform: rotateY(180deg);
          border: 1px solid #eee;
          box-shadow: 5px 6px 6px 2px #e9ecef;
          padding: 10px;
          opacity: 0;
          transition-property: opacity;
          transition-duration: 0.2s;
          transition-delay: 0.2s;
          overflow-y: scroll;
          scrollbar-width: thin;


          .about {
            font-size: 12px;
          }
          p {
            color: #fff !important;
          }
        }
      }

      .thecard:hover {
        transform: rotateY(180deg);
        .theback{
          opacity: 1;
        }
      }
    } 

    .card-intern{
      position: relative;
      display: flex;
      padding: 20px;

      .intern-left-odd{
        // background-color: white;
        width: 20%;
      }

      .intern-right-odd{
        width: 80%;
        border-radius: 10px;
        box-shadow: 1px 2px 8px 0px #a0a0a0;

        .intern-icon-odd{
          position: absolute;
          transform: translate(-50%, -50%);
          border: 6px solid $color-blue;
          box-shadow: 1px -1px 8px 0px #2A5CB7;
          border-radius: 10px;
        }

        .intern-content-con-odd{
          color: white;
          padding: 70px 70px 70px 200px;

          .intern-header{
            font-size: $font-xl;
            text-align: center;
          }

          .intern-content{
            font-size: $font-s;
            text-align: justify;
          }
        }
      }

      .intern-left-even{
        width: 80%;
        border-radius: 10px;
        box-shadow: 1px 2px 8px 0px #a0a0a0;

        .intern-icon-even{
          width: fit-content;
          position: absolute;
          border: 6px solid $color-green;
          box-shadow: 1px -1px 8px 0px #28af6f;
          border-radius: 10px;
        }

        .intern-content-con-even{
          color: white;
          padding: 70px 200px 70px 70px;

          .intern-header{
            font-size: $font-xl;
            text-align: center;
          }

          .intern-content{
            font-size: $font-s;
            text-align: justify;
          }
        }
      }

      .intern-right-even{
        // background-color: white;
        width: 20%;
      }
    }
  }

  .job-opening{
    font-family: 'Sniglet', cursive;

    .job-opening-header{
      height: 160px;
      background: url('http://www.geertjanhendriks.nl/codepen/form/golf.png') repeat-x bottom;
      
      h1{
        font-weight: normal;
        font-size: 50px;
        font-family: 'Raleway', sans-serif;
        margin: 0 auto;
        margin-top: 30px;
        // width: 510px;
        color: #F90;
        text-align: center;
      }
    }

    .job-opening-form{
      height: 100%; 
      background-color: #98d4f3;
      overflow: hidden;
      position: relative;

      .fish{
        background-image: url('http://www.geertjanhendriks.nl/codepen/form/fish.png');
        width: 235px;
        height: 104px;
        margin-left: -235px;
        position: absolute; 
        animation: myfirst 24s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }

      #fish{
        top: 130px;
      }

      #fish2{
        top: 400px;
        animation-delay: 12s;
      }

      .job-opening-waterform{
        // margin: 0 auto;
        // width: 500px;
        padding-top: 40px;
        color: white;
        position: relative;

        .my-formgroup, .my-formgroup-active, .my-formgroup-error{
          background-repeat: no-repeat;
          background-position: right bottom;
          background-size: 10.5%;
          transition: background-image 0.7s;
          width: 100%;
          padding-top: 2px;
        }

        label, input, textarea, select, option{
          display: block; 
        }

        input, textarea, select, option{
          width: 100%; 
          border: none;
          border-radius: 20px;
          outline: none;
          padding: 10px;
          font-family: 'Sniglet', cursive;
          font-size: 1em;
          color: #676767;
          transition: border 0.5s;
          border: solid 3px #98d4f3;  
          box-sizing:border-box;
        }

        input:focus, textarea:focus, select:focus, option:focus{
          border: solid 3px #77bde0;  
        }

        textarea{
          resize: none; 
          overflow: auto;
        }

        input[type="submit"]{
          background-color: #F90;
          color: white;
          height: 50px;
          cursor: pointer;
          margin-top: 30px;
          font-size: 1.29em;
          font-family: 'Sniglet', cursive;
          transition: background-color 0.5s;
        }

        input[type="submit"]:hover{
          background-color: #e58f0e;
        }
        label{
          font-size: 1.5em;
          margin-top: 20px;
          padding-left: 20px;
        }
      }
    }
  }
}

/* Animation */
@keyframes myfirst
{
  0% {margin-left: -235px}
  70% {margin-left: 100%;}
  100% {margin-left: 100%;}
}

.my-formgroup{
  background-image: url('http://www.geertjanhendriks.nl/codepen/form/pixel.gif'); 
}
.my-formgroup-active{
  background-image: url('http://www.geertjanhendriks.nl/codepen/form/octo.png');
}
.my-formgroup-error{
  background-image: url('http://www.geertjanhendriks.nl/codepen/form/octo-error.png');
  color: red;
}

@media (max-width : 1024px) {
  .get-involved{
    .section-get-involved{
      .card-intern-odd{
        display: block;

        .intern-left-odd{
          width: 100%;
          height: 180px;
        }

        .intern-right-odd{
          width: 100%;

          .intern-icon-odd{
            left: 50%;
          }

          .intern-content-con-odd{
            padding: 150px 20px 20px 20px;
          }
        }
      }
    }
  }
}

@media (min-width : 1025px) {
  .get-involved{
    .section-get-involved{
      .card-intern-odd{
        .intern-right-odd{
          .intern-icon-odd{
            top: 50%;
          }
        }
      }
    }
  }
}

@media (max-width : 1279px) {
  .get-involved{
    .section-get-involved{
      .card-intern-even{
        display: flex;
        flex-wrap: wrap-reverse;

        .intern-right-even{
          width: 100%;
          height: 180px;
        }

        .intern-left-even{
          width: 100%;

          .intern-icon-even{
            transform: translate(-50%, -50%);
            left: 50%;
          }

          .intern-content-con-even{
            padding: 140px 20px 20px 20px;
          }
        }
      }
    }
  }
}

@media (min-width : 1280px) {
  .get-involved{
    .section-get-involved{
      .card-intern-even{
        .intern-left-even{
          .intern-icon-even{
            transform: translate(-50%, -50%);
            right: 0;
            top: 50%;
          }
        }
      }
    }
  }
}